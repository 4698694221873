<template>
    <v-carousel :show-arrows="false" height="800px" v-model="bookstepslider">
    <v-carousel-item>
        
        <v-sheet class="py-6 pb-1" :min-height="800">
           



            <div class="container-">
                <div class="d-flex flex-wrap justify-space-between">
                    <v-avatar size="80" class="mr-6">
                        <v-img
                            class=""
                            
                            :src="profileimg + '?key=tmb'"
                            :placeholder="'https://ik.imagekit.io/platt4mresources/massageapp/8714556a52021ba3a55c8e7a3547d28c.jpg?updatedAt=1731701496621'"
                            alt=""
                        />
                    </v-avatar>
                    <div class="flex-1" v-if="details">
                        <div class="d-flex align-start justify-space-between">
                            <div class="mb-3">
                                <div class="mr-2 mb-0">
                                    {{ details.firstname + ' ' + details.lastname}}

                                    <v-tooltip top v-if="details.verified_badge">
                                        <template
                                            v-slot:activator="{on, attrs}"
                                        >
                                            <v-icon
                                                class="body-1"
                                                color="primary"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                mdi-check-decagram</v-icon
                                            >
                                        </template>
                                        <span>Verified</span>
                                    </v-tooltip>
                                    
                                </div>
                                <div class="mt-2">
                                    <v-chip :key="group.code" v-for="(group) in details.service_type_groups" class="mr-2" color="orange" outlined x-small>
                                        {{ group["title_" + $root.$i18n.locale] }}
                                    </v-chip>                                    
                                </div>
                            </div>                            
                            <v-btn fab color="lightgrey" x-small style="opacity: 0.9;" depressed @click.stop="$emit('clickclose')">
                                <v-icon>
                                    mdi-close
                                </v-icon>                            
                            </v-btn>
                        </div>
                        <div class="d-flex  align-start">
                            <v-rating
                                v-model="details.rating"
                                background-color="primary"
                                color="primary"                                
                                readonly
                                dense
                                class="mb-3-"
                            ></v-rating>
                            <v-btn
                                v-if="favorite"
                                class="shadow-sm ml-3"
                                fab
                                color="danger"
                                x-small
                                dark        
                                @click.stop="favorite=!favorite"                    
                            >
                                <v-icon>mdi-heart</v-icon>
                            </v-btn>
                            <v-btn
                                v-else
                                class="shadow-sm ml-3"
                                x-small
                                fab                                                         
                                @click.stop="favorite=!favorite"
                            >
                                <v-icon>mdi-heart-outline</v-icon>
                            </v-btn>
                        </div>
                        <div class="d-flex  align-start">
                            <p class="ma-0 mr-8">
                                <span class="font-weight-medium text-caption"> {{ details.statistics.completed_sessions || 0 }} </span
                                ><span class="body-2 text--disabled text-caption"
                                    >Ραντεβού</span
                                >
                            </p>
                            <p class="ma-0 mr-8">
                                <span class="font-weight-medium text-caption">3 </span
                                ><span class="body-2 text--disabled text-caption"
                                    >Χρόνια Εμπειρίας</span
                                >
                            </p>
                            
                        </div>                                                                
                    </div>

                    <!--<div class="flex-1 text-right" :class="{float_buttons_book: floatActionButtons, 'mt-4': !floatActionButtons}">
                        
                        <v-btn
                            v-if="favorite"
                            class="mr-2 shadow-sm"
                            fab
                            color="danger"
                            x-small
                            dark        
                            @click.stop="favorite=!favorite"                    
                        >
                            <v-icon>mdi-heart</v-icon>
                        </v-btn>
                        <v-btn
                            v-else
                            class="mr-2 shadow-sm"
                            x-small
                            fab                                                         
                            @click.stop="favorite=!favorite"
                        >
                            <v-icon>mdi-heart-outline</v-icon>
                        </v-btn>
                        <v-btn v-if="!isPreview" class="ma-2 text-capitalize" color="#eb8e45" style="opacity: 0.9;" depressed @click.stop="$emit('clickbook')">
                            <v-icon>
                                mdi-file-document-plus-outline
                            </v-icon>
                            &nbsp;
                            Ραντεβού
                        </v-btn>
                    </div>      -->              
                </div>                
            </div>
            <hr class="mt-2" />   





            <v-subheader :class="{ shake: startshakinglabel }">Επιλέξτε Υπηρεσίες</v-subheader>
            <div v-if="details">
                <v-chip
                    v-for="(selection, i) in serviceSelection"
                    :key="i"
                    :color="selection.selected ? 'green' : '#ffb56b'"                                                  
                    class="ma-1"      
                    :style="{color: selection.selected ? 'white' : 'inherit'}"
                    @click="toggleSelectService(selection)"         
                    small                 
                    >
                    <v-icon v-if="selection.selected"
                        left
                        small
                    >
                        mdi-check-circle
                    </v-icon>
                    <v-icon v-else
                        left
                        small
                    >
                        mdi-plus
                    </v-icon>
                    {{ selection["title_" + $root.$i18n.locale] }} {{ selection.duration + '\' / ' + selection.price + '€'  }}
                </v-chip>
            </div>
        
        <base-card class="transparent mt-4">
            <v-subheader>Επιλέξτε Ώρα Ραντεβού</v-subheader>
            <div v-if="details" class="slotpicker-header">
                <div class="slotpicker-side"><v-icon v-if="cal_offset>0" @click="cal_offset--" size="35">mdi-arrow-left-bold-box</v-icon></div>
                <div v-for="d in details.slots" :key="d.datestring" class="slotpicker-column">
                    <div class="slotpicker-weekday">{{ d.weekday.substring(0,3) }}</div>
                    <div class="slotpicker-date">{{ d.datestring.substring(0,5) }}</div>                            
                </div>
                <div class="slotpicker-side"><v-icon @click="cal_offset++" size="35">mdi-arrow-right-bold-box</v-icon></div>
            </div>
            
            <vue-perfect-scrollbar
                v-if="details"
                :settings="{ suppressScrollX: true, wheelPropagation: false }"
                class="rtl-ps-none scroll"
                style="height: 100%"
            >
                <div class="slotpicker-container">
                    <div class="slotpicker-side"></div>
                    <div v-for="d in details.slots" :key="d.datestring" class="slotpicker-column">                                    
                        <div class="slotpicker-slotcolumn">                                
                            <v-btn v-for="s in d.slots" @click="clickSlot(d.datestring.substring(0,5) + '|' + s.time)" color="#ffbb77" :disabled="!s.available" :key="d.datestring + '_' + s.time" small block class="mt-3">{{ s.time }}</v-btn>
                            <hr style="color: lightgray; margin-top: 50px;"/>
                        </div>
                    </div>
                    <div class="slotpicker-side"></div>
                </div>                                    

            </vue-perfect-scrollbar>            
        </base-card> 





        </v-sheet>
           
    </v-carousel-item>















    <v-carousel-item>
        <v-sheet class="px-6 py-12 pb-1 flex-1">
            <div class="container-">
                <div class="d-flex flex-wrap justify-space-between">
                    <v-avatar size="80" class="mr-6">
                        <v-img
                            class=""
                            
                            :src="profileimg + '?key=tmb'"
                            :placeholder="'https://ik.imagekit.io/platt4mresources/massageapp/8714556a52021ba3a55c8e7a3547d28c.jpg?updatedAt=1731701496621'"
                            alt=""
                        />
                    </v-avatar>
                    <div class="flex-1" v-if="details">
                        <div class="d-flex align-start justify-space-between">
                            <div class="mb-3">
                                <div class="mr-2 mb-0">
                                    {{ details.firstname + ' ' + details.lastname}}

                                    <v-tooltip top v-if="details.verified_badge">
                                        <template
                                            v-slot:activator="{on, attrs}"
                                        >
                                            <v-icon
                                                class="body-1"
                                                color="primary"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                mdi-check-decagram</v-icon
                                            >
                                        </template>
                                        <span>Verified</span>
                                    </v-tooltip>
                                    
                                </div>
                                <div class="mt-2">
                                    <v-chip :key="group.code" v-for="(group) in details.service_type_groups" class="mr-2" color="orange" outlined x-small>
                                        {{ group["title_" + $root.$i18n.locale] }}
                                    </v-chip>                                    
                                </div>
                            </div>                            
                            <v-btn fab color="lightgrey" x-small style="opacity: 0.9;" depressed @click.stop="bookstepslider=0">
                                <v-icon>
                                    mdi-arrow-left
                                </v-icon>                            
                            </v-btn>
                        </div>
                        <div class="d-flex  align-start">



                        </div>
                        <div class="d-flex  align-start">

                        </div>                                                                
                    </div>
          
                </div>                
            </div>
            <hr class="mt-2" />









            <v-row>
                                    <v-col cols="12" sm="6" class="pa-0">
                                        <v-list>
                                            <v-list-item>
                                                <h6 class="mb-0"><v-icon>mdi-map-marker</v-icon>&nbsp;Τοποθεσία</h6>
                                            </v-list-item>
                                            <v-list-item class="pl-4">
                                                <p class="mb-0">{{ myCurrentLocation.text }}</p>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                    <v-col cols="12" sm="6" class="pa-0">
                                        <v-list>
                                            <v-list-item>
                                                <h6 class="mb-0"><v-icon>mdi-calendar</v-icon>&nbsp;Ημερομηνία & Ώρα</h6>
                                            </v-list-item>
                                            <v-list-item class="pl-4">
                                                <h4 class="mb-0" style="color: black;">{{ getCartTimeslot ? getCartTimeslot.split("|")[0] : '' }} ώρα {{ getCartTimeslot ? getCartTimeslot.split("|")[1] : '' }} για {{ getCartDuration }}'</h4>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                </v-row>
                                <v-row class="text-center">
                                    <v-col cols="12" sm="6" class="text-center pa-0">
                                        <v-list>
                                            <v-list-item>
                                                <h6 class="mb-0"><v-icon>mdi-clipboard-list-outline</v-icon>&nbsp;Θεραπείες/Υπηρεσίες</h6>
                                            </v-list-item>
                                            <v-list-item class="pl-4">
                                                <div v-if="details" style="text-align: left;">
                                                    <div v-for="(selection, i) in orderedServices"
                                                    :key="i">
                                                        <v-chip
                                                            
                                                            color="#ffb56b"
                                                            class="ma-1"                                                                  
                                                            small                 
                                                            >                                                        
                                                            {{ selection["title_" + $root.$i18n.locale] }} {{ selection.duration + '\' / ' + selection.price + '€'  }}
                                                        </v-chip>
                                                    </div>                                                    
                                                </div>                                                
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                    <v-col cols="12" sm="6" class="pa-0">
                                        <v-list>
                                            <v-list-item>
                                                <h6 class="mb-0"><v-icon>mdi-cash-multiple</v-icon>&nbsp;Κόστος Υπηρεσιών</h6>
                                            </v-list-item>
                                            <v-list-item class="pl-4">
                                                <h4 class="mb-0" style="color: black;">{{getCartPrice}}€</h4>
                                            </v-list-item>        
                                            <v-list-item>
                                                <span class="mb-0" style="text-align: left;">Το ποσό που αντιστοιχεί στην αμοιβή του θεραπευτή.<br/>Το πληρώνετε απευθείας στον θεραπευτή κατά το ραντεβού.</span>
                                            </v-list-item>                                                                               
                                        </v-list>
                                    </v-col>
                                    <v-col cols="12" class="pa-0">
                                        <v-list>
                                            <v-list-item class="d-flex justify-center mt-8">
                                                <h6 class="mb-0"><v-icon>mdi-cash-register</v-icon>&nbsp;Κόστος Κράτησης</h6>
                                            </v-list-item>
                                            <v-list-item  class="d-flex justify-center">
                                                <h4 class="mb-0" style="color: black;">{{order_payment_amount}}€</h4>
                                            </v-list-item>
                                            <v-list-item class="d-flex justify-center">
                                                <span class="mb-0">Το ποσό που πληρώνετε άμεσα<br/>για την πραγματοποίηση της κράτησης</span>
                                            </v-list-item>                                                                                        
                                            <v-list-item class="mt-4 pl-4 d-flex justify-center flex-column">
                                                
                                                <v-btn color="primary" dark class="text-capitalize" width="320">
                                                    <v-icon>mdi-credit-card-fast-outline</v-icon>
                                                    &nbsp;&nbsp;&nbsp;Πληρωμή
                                                </v-btn>  
                                                <v-img class="mt-4" src="@/assets/images/viva GR large.svg"></v-img>                                              
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                </v-row>
















        </v-sheet>        
    </v-carousel-item>
  </v-carousel>    
</template>
<script>
import avatarGroupCard from '@/components/card/AvatarGroupCard';
import { defaultTo } from 'lodash';
import {VueperSlides, VueperSlide} from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
import {api, srvApi, srvApiNoAuth, PATH, getAssetUrl} from "src/api/index";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
    name: 'profiledetails',
    metaInfo: {
        // title will be injected into parent titleTemplate
        //title: 'Profile 1'
    },
    components: {
        avatarGroupCard,
        VueperSlides, 
        VueperSlide 
    },
    props: {
        initdetails: {
            type: Object,
            default: null
        },        
        previewMode: {
            type: Boolean,
            default: false
        },
        therapist_code: {
            type: String,
            default: null
        },
        selectedaddress: {

        },
    },
    watch: {
        async slotrange(newvalue) {            
            const rspTherapist = await srvApi.get(PATH.BOOKING_THERAPIST + "/" + this.therapist_code + "?offset=" + this.cal_offset + "&range=" + this.slotrange );
            this.details = rspTherapist.data;
            this.$forceUpdate();
        },
        async cal_offset(newvalue) {
            const rspTherapist = await srvApi.get(PATH.BOOKING_THERAPIST + "/" + this.therapist_code + "?offset=" + this.cal_offset + "&range=" + this.slotrange );
            this.details = rspTherapist.data;
            this.$forceUpdate();
        }
    },
    computed: {
        ...mapGetters(['myLocations','myCurrentLocation', "getCart", "getCartList", "getCartTimeslot", "getCartDuration", "getCartPrice"]),        
        order_services_total() {
            let total=0;
            for(let s of this.getCartList) {
                total += s.price;             
            }
            return total;
        },
        floatActionButtons() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return true
                case 'sm': return true
                case 'md': return false
                case 'lg': return false
                case 'xl': return false
            }
        },
        slotrange() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 2
                case 'sm': return 2
                case 'md': return 3
                case 'lg': return 7
                case 'xl': return 7
            }
        },
        profileimg() {
            if(this.details) {
                return getAssetUrl(this.details.profile_photo);
            }            
            return "";
        },
        extraimages() {
            return this.details && this.details.extra_images ? this.details.extra_images.map(x=>getAssetUrl(x)) : [];
        },
        serviceSelection() {
            if(this.details && this.details.services) {
                let rslt = this.details.services.map(s=>({...s, selected: this.getCartList.find(a=>a.code == s.code) != null })).sort((a,b)=> a.service_type == 'free60' ? -1 : 1);
                return rslt;
            }
            else {
                return [];
            }
        },
        orderedServices() {
            if(this.details && this.details.services) {
                let rslt = this.details.services.filter(s=> this.getCartList.find(a=>a.code == s.code) != null); //(s=>({...s, selected: this.getCartList.find(a=>a.code == s.code) != null }));
                return rslt;
            }
            else {
                return [];
            }
        },
    },
    data() {
        return {
            bookstepslider: 0,

            order_payment_amount: 2,  
            order_slot: "",                           

            details: null,
            isPreview: false,
            selectedTimeslot: null,
            favorite: false,


            cal_offset: 0,
            cal_range: 7,


            startshakinglabel: false,


            seasons: ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00','24:00'],

            help_rating: 4,


            therapistServices: [
                {
                    title: "Μασάζ Προσώπου",                    
                    options: [
                        { //€
                            price: "15",
                            duration: "30'",
                            default: false,
                        },
                        { //€
                            price: "30",
                            duration: "60'",
                            default: true,
                        }
                    ],
                    selected: false,
                    selectedDuration: "60'"
                },
                {
                    title: "Μασάζ Πλάτης",
                    options: [
                        { //€
                            price: "20",
                            duration: "30'",
                            default: false,
                        },
                        { //€
                            price: "20",
                            duration: "60'",
                            default: true,
                        }
                    ],
                    selected: false,
                    selectedDuration: "60'"
                },
                {
                    title: "Μασάζ Αυχένα",
                    options: [
                        { //€
                            price: "10",
                            duration: "15'",
                            default: false,
                        },
                        { //€
                            price: "20",
                            duration: "30'",
                            default: true,
                        }
                    ],
                    selected: false,
                    selectedDuration: "30'"
                },
                {
                    title: "Shiatsu",
                    options: [
                        { //€
                            price: "10",
                            duration: "15'",
                            default: false,
                        },
                        { //€
                            price: "20",
                            duration: "30'",
                            default: true,
                        }
                    ],
                    selected: false,
                    selectedDuration: "30'"
                },
                {
                    title: "Λεμφικό Μασάζ",
                    options: [
                        { //€
                            price: "30",
                            duration: "30'",
                            default: false,
                        },
                        { //€
                            price: "40",
                            duration: "60'",
                            default: true,
                        },
                        { //€
                            price: "60",
                            duration: "90'",
                            default: false,
                        }                    
                    ],
                    selected: false,
                    selectedDuration: "60'"
                },
            ],


            cards: [
            {
                title: 'Beats Headphone',
                details: 'Enjoy Premium Sound',
                price: '39',
                img: require('@/assets/images/products/headphone-1.jpg')
            },
            {
                title: 'Iphone 11pro',
                details: '6G , 4k FPS',
                price: '119',
                img: require('@/assets/images/products/watch-1.jpg')
            },
            {
                title: 'Iphone 11pro',
                details: '6G , 4k FPS',
                price: '119',
                img: require('@/assets/images/products/watch-1.jpg')
            },
            {
                title: 'Beats Headphone',
                details: 'Enjoy Premium Sound',
                price: '39',
                img: require('@/assets/images/products/headphone-1.jpg')
            },
            {
                title: 'Iphone 11pro',
                details: '6G , 4k FPS',
                price: '119',
                img: require('@/assets/images/products/watch-1.jpg')
            }
        ],
        slides: [
            {image: require('@/assets/images/products/headphone-1.jpg')},
            {image: require('@/assets/images/products/headphone-2.jpg')},
            {image: require('@/assets/images/products/iphone-1.jpg')},
            {image: require('@/assets/images/products/speaker-1.jpg')},
            {image: require('@/assets/images/products/watch-2.jpg')},
            {image: require('@/assets/images/products/headphone-2.jpg')},
            {image: require('@/assets/images/products/headphone-1.jpg')},
            {image: require('@/assets/images/products/headphone-2.jpg')}
        ],

            tab: null,
            items: [
                // {tab: 'Timeline', content: 'Tab 1 Content'},                
                {tab: 'Availability', content: 'Tab 2 Content'},
                {tab: 'Photos', content: 'Tab 2 Content'}
                
            ],
            infos: [
                {
                    title: 'Country',
                    subtitle: 'USA',
                    icon: 'mdi-home'
                },
                {
                    title: 'Relationship',
                    subtitle: 'Single',
                    icon: 'mdi-heart'
                },
                {
                    title: 'Form',
                    subtitle: 'New York',
                    icon: 'mdi-map'
                },
                {
                    title: 'Studied @',
                    subtitle: 'University Of UI Lib',
                    icon: 'mdi-school'
                },
                {
                    title: 'Lives In',
                    subtitle: 'Texas',
                    icon: 'mdi-google-maps'
                },
                {
                    title: 'Followers',
                    subtitle: '120k Followers',
                    icon: 'mdi-bell'
                }
            ],
            friends: [
                {
                    img: require('@/assets/images/faces/1.jpg'),
                    title: 'Tim Clarkson',
                    subtitle:
                        '<span class="text--disabled"> 2 mutual friend </span>',
                    iconColor: 'warning'
                },
                {
                    img: require('@/assets/images/faces/2.jpg'),
                    title: 'Jhon Weaver',
                    subtitle:
                        '<span class="text--disabled"> 2 mutual friend </span>',
                    iconColor: ''
                },
                {
                    img: require('@/assets/images/faces/3.jpg'),
                    title: 'Kane Frost',
                    subtitle:
                        '<span class="text--disabled"> 2 mutual friend </span>',
                    iconColor: 'warning'
                },
                {
                    img: require('@/assets/images/faces/4.jpg'),
                    title: 'Kane Frost',
                    subtitle:
                        '<span class="text--disabled"> 2 mutual friend </span>',
                    iconColor: 'warning'
                },
                {
                    img: require('@/assets/images/faces/5.jpg'),
                    title: 'Drow Ranger',
                    subtitle:
                        '<span class="text--disabled"> 2 mutual friend </span>',
                    iconColor: 'warning'
                },
                {
                    img: require('@/assets/images/faces/4.jpg'),
                    title: 'Juggernaut Frost',
                    subtitle:
                        '<span class="text--disabled"> 2 mutual friend </span>',
                    iconColor: ''
                }
            ],
            images: [
                {img: require('@/assets/images/gallery/sq-10.jpg')},
                {img: require('@/assets/images/gallery/sq-11.jpg')},
                {img: require('@/assets/images/gallery/sq-12.jpg')},
                {img: require('@/assets/images/gallery/sq-13.jpg')}
            ],
            country: [
                {
                    img: require('@/assets/images/country/dubai.jpg'),
                    title: 'Dubai',
                    subtitle: '<span class="text--disabled"> 2 days ago </span>'
                },
                {
                    img: require('@/assets/images/country/new_york.jpg'),
                    title: 'New York, USA',
                    subtitle:
                        '<span class="text--disabled"> 2 years ago </span>'
                },
                {
                    img: require('@/assets/images/country/china.jpg'),
                    title: 'Shanghai, China',
                    subtitle:
                        '<span class="text--disabled"> 11 months ago </span>'
                },
                {
                    img: require('@/assets/images/country/paris.jpg'),
                    title: 'Paris, France',
                    subtitle:
                        '<span class="text--disabled"> 11 months ago </span>'
                }
            ]
        }
    },
    methods: {
        ...mapActions(["addToCart", "setCartTherapist", "setCart", "removeFromCart", "setCartTimeslot"]),
        clickSlot: async function(slotdatetime) {
            this.setCartTimeslot(slotdatetime);
            let cartWithCalcs = await srvApi.post(PATH.BOOKING_CART, this.getCart);
            cartWithCalcs = {...cartWithCalcs.data};
            this.setCart(cartWithCalcs);            
            if(this.getCartList == null || (Array.isArray(this.getCartList) && this.getCartList.length == 0))
            {
                const self = this
                self.startshakinglabel = true
                setTimeout(() => {
                    self.startshakinglabel = false
                }, 1000)
            }           
            else {
                setTimeout(() => {
                    this.bookstepslider=1;
                }, 300)                
            } 
        },
        toggleSelectService: function(service) {
            console.log(JSON.stringify(service, null, 2));
            if(this.getCartList.find(s=> s.code == service.code) == null) {
                this.addToCart(service);
            }
            else {
                this.removeFromCart(service);
            }            
        },
        getDateString: function(dt_string) {
            var weekday=new Array(7);
            weekday[1]="Mon";
            weekday[2]="Tue";
            weekday[3]="Wed";
            weekday[4]="Thu";
            weekday[5]="Fri";
            weekday[6]="Sat";
            weekday[0]="Sun";
            
            var dt = new Date(dt_string);
            const dayWeek = dt.getUTCDay();
            
            return `${weekday[dayWeek]}, ${dt.getUTCDate()}/${dt.getUTCMonth()+1}`;
        }
    },
    async mounted() {
        // this.isPreview = this.previewMode;

        // if(this.details == null && this.therapist_code != null) {
        //     const rspTherapist = await srvApi.get(PATH.BOOKING_THERAPIST + "/" + this.therapist_code + "?offset=" + this.cal_offset + "&range=" + this.cal_range );
        //     this.details = rspTherapist.data;
        //     // this.selectedTherapist = [rspTherapist.data].map(t=>({
        //     //     img: getAssetUrl(t.profile_photo),
        //     //     title: t.firstname + " " + t.lastname,
        //     //     subTitle: t.short_description,
        //     //     progressLinearColor: 'primary',
        //     //     rating: t.rating,
        //     //     ratingCount: t.rating_count,
        //     //     details: {...t}
        //     // }))[0];
        // }

        this.setCartTherapist(this.therapist_code);        

        this.isPreview = this.previewMode;

        const rspTherapist = await srvApi.get(PATH.BOOKING_THERAPIST + "/" + this.therapist_code + "?offset=" + this.cal_offset + "&range=" + this.slotrange );
        this.details = rspTherapist.data;        
        if(this.getCartList && this.getCartList.length==0) {
            let defaultService = this.details.services.find(s=>s.service_type=='free60');
            if(defaultService) {
                this.addToCart(defaultService);
            }
        }
    },
    async created() {
        
    }
}
</script>
<style lang="scss">

.v-carousel__controls {
    display: none !important;
}

.float_buttons_book {
    position: absolute; 
    right: 10px;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
}



.slotpicker-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.slotpicker-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    max-height: 400px;
}

.slotpicker-column {
    height: 100%;
    padding: 5px;
    //background-color: red;
    margin: 5px;
    flex-grow: 1;
}

.slotpicker-side {
    width: 40px;
    height: 100%;
    padding: 5px;
    //background-color: red;
    margin: 5px;
}

.slotpicker-weekday {
    text-align: center;
}

.slotpicker-date {
    text-align: center;
}

.slotpicker-slotcolumn {
    text-align: center;
}












.shake {
    font-weight: 800;
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

</style>
